import React from 'react'
import styled from 'styled-components'

const IconRssStyle = styled.div`
    cursor: pointer;

    :hover {
        svg g {
            fill: var(--accent-color);
        }
    }
`

const IconRss = ({ socialLink }) => (
    <a href={socialLink} target="blank">
        <IconRssStyle>
            <svg
                width="39px"
                height="38px"
                viewBox="0 0 39 38"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title>icon-rss</title>
                <desc>Created with Sketch.</desc>
                <g
                    id="icon-rss"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    transform="translate(0.000000, 0.000000)"
                >
                    <path
                        d="M13.005065,28.9974693 C13.005065,31.2080325 11.2130644,33.000033 9.00250123,33.000033 C6.79193805,33.000033 5,31.2080325 5,28.9974693 C5,26.7869061 6.79200055,24.9949055 9.00256373,24.9949055 C11.2131269,24.9949055 13.005065,26.7869686 13.005065,28.9974693 L13.005065,28.9974693 Z M23.9838183,31.9505952 C23.4616932,22.2880922 15.7222533,14.5389023 6.04943782,14.0162147 C5.47850015,13.9853397 5,14.4440898 5,15.01584 L5,18.0201534 C5,18.5460911 5.40562512,18.9871537 5.93043778,19.0212787 C12.9199399,19.4765289 18.5225041,25.0651556 18.9786918,32.0695327 C19.0128793,32.5943454 19.4539419,32.9999705 19.9798171,32.9999705 L22.9841305,32.9999705 C23.5559432,32.9999705 24.0146933,32.5215329 23.9838183,31.9505952 Z M32.9993836,31.9685952 C32.4747584,17.3548407 20.7165673,5.52783709 6.03143782,5.00064943 C5.46706264,4.98039942 5,5.43633706 5,6.00102474 L5,9.00527565 C5,9.54433832 5.42718763,9.98308845 5.9658128,10.0052135 C17.914504,10.4951511 27.5050069,20.0874665 27.994882,32.0342827 C28.0169445,32.5729079 28.4556947,33.0000955 28.9948198,33.0000955 L31.9990708,33.0000955 C32.5636959,33.0000955 33.0196336,32.5329704 32.9993836,31.9685952 L32.9993836,31.9685952 Z"
                        id="Shape"
                        stroke="#EE0202"
                        fillRule="nonzero"
                    ></path>
                </g>
            </svg>
        </IconRssStyle>
    </a>
)

export default IconRss
